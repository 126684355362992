exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-content-js": () => import("./../../../src/pages/components/Content.js" /* webpackChunkName: "component---src-pages-components-content-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-picture-js": () => import("./../../../src/pages/components/HeaderPicture.js" /* webpackChunkName: "component---src-pages-components-header-picture-js" */),
  "component---src-pages-components-logo-js": () => import("./../../../src/pages/components/Logo.js" /* webpackChunkName: "component---src-pages-components-logo-js" */),
  "component---src-pages-components-main-article-js": () => import("./../../../src/pages/components/MainArticle.js" /* webpackChunkName: "component---src-pages-components-main-article-js" */),
  "component---src-pages-components-main-layout-js": () => import("./../../../src/pages/components/MainLayout.js" /* webpackChunkName: "component---src-pages-components-main-layout-js" */),
  "component---src-pages-components-top-bar-js": () => import("./../../../src/pages/components/TopBar.js" /* webpackChunkName: "component---src-pages-components-top-bar-js" */),
  "component---src-pages-components-video-clip-js": () => import("./../../../src/pages/components/VideoClip.js" /* webpackChunkName: "component---src-pages-components-video-clip-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

